import React from "react";
import NavBar from "../../components/navBar";
import styles from "./styles.module.css";
import EnquiryForm from "../../components/enquiryForm";
import google from "../../assets/google.png";
import instagram from "../../assets/instagram.png";
import facebook from "../../assets/facebook.png";
import linkedin from "../../assets/linkedin.png";
import email from "../../assets/envelope.png";
import pin from "../../assets/pin.png";
import phone from "../../assets/phone-call.png";
import clock from "../../assets/wall-clock.png";
import Footer from "../../components/footer";
import neww from "../../assets/Mention-rafiki (2).svg";
import feedbackkk from "../../assets/Graduation.gif";

import isMobile from "is-mobile";
export default class Enquire extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  openSocialLink = (link: string) => {
    window.open(link);
  };
  render() {
    return (
      <div>
        <NavBar></NavBar>
        <div className={styles.continer}>
          {isMobile() && (
            <div className={styles.flex2}>
              <EnquiryForm />
            </div>
          )}
          <div className={styles.flex1}>
            <div className={styles.contact_container}>
              <div className={styles.address_container}>
                <div>
                  <img className={styles.imgmg} src={pin} />
                </div>

                <div className={styles.info_data}>
                  Ekka Complex, 1st Floor, Behind Naguri Bus Stand, Naguri,
                  Mangalore, Karnataka - 575002
                </div>
              </div>
              <div className={styles.email_container}>
                <div>
                  <img className={styles.imgmg} src={email} />
                </div>
                <div className={styles.info_data}>
                  ignitedmindsmangalore@outlook.com
                </div>
              </div>
              <div className={styles.email_container}>
                <div>
                  <img className={styles.imgmg} src={phone} />
                </div>
                <div className={styles.info_data}>
                  +91-9986117622, +91-7204094026
                </div>
              </div>
              <div className={styles.timing_container}>
                <div>
                  <img className={styles.imgmg} src={clock} />
                </div>
                <div className={styles.info_data}>
                  <div> Mon - Sat </div>
                  <div>10:00 AM - 6:00 PM</div>
                </div>
              </div>
              {/* <div className={styles.ssss}>
                <div>Follow Us</div>
                <div className={styles.social_media_cont}>
                  <img
                    onClick={() =>  window.open("https://g.co/kgs/ExuAkd")}
                    src={google}
                  ></img>
                  <img
                    onClick={() =>
                      window.open(
                        "https://instagram.com/ignitedminds_mangalore?utm_medium=copy_link"
                      )
                    }
                    src={instagram}
                  ></img>

                  <img src={facebook}></img>
                  <img src={linkedin}></img>
                </div>
              </div> */}
              {/* <img src={neww}></img> */}
            </div>
            <img className={styles.feedbbb} src={feedbackkk} />
          </div>

          {!isMobile() && (
            <div className={styles.flex2}>
              <EnquiryForm />
            </div>
          )}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
