import styles from "./styles.module.css";
import car from "../../assets/hero.svg";
import heroBg from "../../assets/heroBG.svg";
import img1 from "../../assets/group_2.png";
import instagram from "../../assets/instagram.png";
import facebook from "../../assets/facebook.png";
import linkedin from "../../assets/linkedin.png";
import google from "../../assets/google.png";
import StoreButton from "../storebutton";
import landing_page_gif from "../../assets/2.Home Banner.gif";
import { useNavigate } from "react-router-dom";
import isMobile from "is-mobile";
import React from "react";

export default function LandingFrame() {
  const storeButtons = ["APP_STORE", "PLAY_STORE"];

  let navigate = useNavigate();

  const handleEnquireClick = () => {
    navigate("../enquire", { replace: true });
  };

  const openSocialLink = (link: string) => {
    window.open(link);
  };

  return (
    <div className={styles.container}>
      {isMobile() && (
        <React.Fragment>
          <div className={styles.m_cont}>
            <img className={styles.hrooGB_m} src={heroBg}></img>
            <img className={styles.dr_image_m} src={car}></img>
          </div>
        </React.Fragment>
      )}
      {!isMobile() && <img className={styles.hrooGB} src={heroBg}></img>}
      {/* <img className={styles.img1} src={img1}></img>
      <img className={styles.img2} src={img2}></img> */}
      <div className={styles.texts_container}>
        <div className={styles.info_container}>
          <div className={styles.text1}>
            A Better <b className={styles.build}>Education Future</b>
          </div>
          <div className={styles.text2}>Starts Here</div>
          <div className={styles.text3}>
            {/* We are always availed to consult on taking your higher education to
            next level so you can stay competitive */}
            Committed to educating and nurturing all students so they may grow
            towards responsible global citizenship
          </div>
          <button className={styles.explore} onClick={handleEnquireClick}>
            ENQUIRE
          </button>

          {/* <div className={styles.social_media_cont}>
            <img
              onClick={() => openSocialLink("https://g.co/kgs/ExuAkd")}
              src={google}
            ></img>
            <img
              onClick={() =>
                openSocialLink(
                  "https://instagram.com/ignitedminds_mangalore?utm_medium=copy_link"
                )
              }
              src={instagram}
            ></img>

            <img src={facebook}></img>
            <img src={linkedin}></img>
          </div> */}

          {/* <div className={styles.text3}>
            Concepts made easy with <b>Dr.Polaris</b>
          </div> */}
          {/* <div className={styles.button_container}>
            {storeButtons.map((storeName) => {
              return (
                <div className={styles.button_single_container}>
                  <StoreButton type={storeName} />
                </div>
              );
            })}
          </div> */}
        </div>
      </div>
      {!isMobile() && (
        <div className={styles.img_container}>
          <img className={styles.dr_image} src={car}></img>
        </div>
      )}
      <div className={styles.circle}></div>
    </div>

    // <div className={styles.container}>
    //   <img  className = {styles.ddd} src= {landing_page_gif}/>
    // </div>
  );
}
