import styles from "./styles.module.css";
import car from "../../assets/group_322.png";
import ic_ophthalmology from "../../assets/ic_ophthalmology_2.png";
import ic_pathology from "../../assets/ic_pathology.png";
import isMobile from "is-mobile";
import React from "react";
import img2 from "../../assets/rectangle_2.png";
import puzzle from "../../assets/puzzle.png";
import mortarboard from "../../assets/mortarboard.png";
import bookstack from "../../assets/book-stack.png";
import airplane from "../../assets/airplane.png";

export default function About() {
  return (
    <div className={styles.container}>
      <img src={puzzle} className={styles.icon1}></img>
      {/* <img src={mortarboard} className={styles.icon2}></img> */}
      <img src={mortarboard} className={styles.icon3}></img>
      <img src={airplane} className={styles.icon4}></img>
      {/* <img className={styles.img1} src={img1} />
      
      <img className={styles.img2} src={img2} /> */}
      <div className={styles.header}>Ignited Minds</div>
      <div className={styles.description}>
        {/* <div className="something">
          <div className="line"></div>
          <div className="text">Vision</div>
          <div className="line"></div>
        </div> */}
        <div>
          A tradition of excellence. To spread the power of education by
          matching the student’s abilities, performance and desires with the
          best possible career options. Getting into the university of your dreams is our topmost priority.
        </div>
        {/* <div className="something">
          <div className="line"></div>
          <div className="text">Mission</div>
          <div className="line"></div>
        </div> */}

        <div>
          We are committed to the students we serve through integrity, honesty
          and excellence by contributing to the society through the pursuit of
          education at the highest international levels of excellence. To build
          a strong and credible relationship with partner institutions by
          recognizing shared values & goals. We are passionate about bringing
          global education to the doorsteps of students all over India by
          helping them achieve their aspirations.
        </div>
        {/* <div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. labore et dolore magna aliqua. Ut
          enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
          ut aliquip ex ea commodo consequat.
        </div> */}
      </div>
      {/* {!isMobile() && (
        <React.Fragment>
          <img className={styles.first_image} src={ic_ophthalmology}></img>
          <img className={styles.sec_image} src={car}></img>
          <img className={styles.third_image} src={ic_pathology}></img>
        </React.Fragment>
      )} */}
    </div>
  );
}
