import styles from "./styles.module.css";
// import logo from "../../assets/Logo-removebg-preview.svg";
import logo from "../../assets/dd.png";
import google from "../../assets/google.png";
import instagram from "../../assets/instagram.png";
import facebook from "../../assets/facebook.png";
import linkedin from "../../assets/linkedin.png";
import email from "../../assets/envelope.png";
import pin from "../../assets/pin.png";
import phone from "../../assets/phone-call.png";
import clock from "../../assets/wall-clock.png";

export default function Footer() {
  const openSocialLink = (link: string) => {
    window.open(link);
  };
  return (
    <div className={styles.container}>
      <div className={styles.inner_container}>
        <div className={styles.top}>
          <img className={styles.logo} src={logo} />
          {/* <div className={styles.ssss}>
            <div>Follow Us</div>
            <div className={styles.social_media_cont}>
              <img
                onClick={() => openSocialLink("https://g.co/kgs/ExuAkd")}
                src={google}
              ></img>
              <img
                onClick={() =>
                  openSocialLink(
                    "https://instagram.com/ignitedminds_mangalore?utm_medium=copy_link"
                  )
                }
                src={instagram}
              ></img>

              <img src={facebook}></img>
              <img src={linkedin}></img>
            </div>
          </div> */}
        </div>

        <div className={styles.extraInfo}>
          <div className={styles.email_container}>
            <div>
              <img className={styles.imgmg} src={pin} />
            </div>
            <div className={styles.info_data}>
              Ekka Complex, 1st Floor, Behind Naguri Bus Stand, Naguri,
              Mangalore, Karnataka - 575002
            </div>
          </div>

          <div className={styles.email_container}>
            <div>
              <img className={styles.imgmg} src={email} />
            </div>
            <div className={styles.info_data}>
              ignitedmindsmangalore@outlook.com
            </div>
          </div>

          <div className={styles.email_container}>
            <div>
              <img className={styles.imgmg} src={phone} />
            </div>
            <div className={styles.info_data}>
              +91-9986117622, +91-7204094026
            </div>
          </div>

          <div className={styles.email_container}>
            <div>
              <img className={styles.imgmg} src={clock} />
            </div>
            <div className={styles.info_data}>
              <div> Mon - Sat </div>
              <div>10:00 AM - 6:00 PM</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
