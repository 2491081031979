import React from "react";
import LandingFrame from "../../components/landingFrame";
import NavBar from "../../components/navBar";
import About from "../../components/about";
import DownloadBlock from "../../components/downloadBlock";
import ChoiceOfLearing from "../../components/choiceOfLearning";
import Stats from "../../components/stats";
import FeaturedIn from "../../components/featuredIn";
import SocialMediaLinks from "../../components/socialMediaLinks";
import Footer from "../../components/footer";
import MsgFromTutors from "../../components/msgFromTutors";
import UniqueFeatures from "../../components/uniqueFeatures";
import MapComponent from "../../components/mapComponent";
import isMobile from "is-mobile";
import YoutubeVideoGallery from "../../components/youtube";
import FeedbackReq from "../../components/feedbackReq";
import styles from "./styles.module.css";
import clock from "../../assets/Paper map-bro.svg";
export default class Home extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <NavBar></NavBar>
        <LandingFrame></LandingFrame>
        <About></About>
        <UniqueFeatures />
        {/* {!isMobile() && <DownloadBlock></DownloadBlock>} */}
        <ChoiceOfLearing></ChoiceOfLearing>
        {/* <Stats></Stats> */}
        {/* <YoutubeVideoGallery /> */}
        {/* <MsgFromTutors /> */}
        {/* <FeaturedIn /> */}
        {/* <SocialMediaLinks /> */}
        {/* {!isMobile() && (
          <div className={styles.lolll}>
            <div className={styles.flx1}>
              <FeedbackReq />
            </div>
            <div className={styles.flx2}>
              <MapComponent></MapComponent>
            </div>
          </div>
        )} */}
        {/* {isMobile() && <MapComponent></MapComponent>} */}
        <FeedbackReq />

        {!isMobile() && (
          <div className={styles.lolll}>
            <div className={styles.flx1}>
              <img className={styles.dsd} src={clock} />
            </div>
            <div className={styles.flx2}>
              <MapComponent></MapComponent>
            </div>
          </div>
        )}

        {isMobile() && (
          <React.Fragment>
            <img className={styles.dsd} src={clock} />{" "}
            <MapComponent></MapComponent>
          </React.Fragment>
        )}

        <Footer />
      </div>
    );
  }
}
