import styles from "./styles.module.css";

import React from "react";
import Select from "react-select";
import Modal from "react-modal";
import isMobile from "is-mobile";
import PopupModal from "../mailSentModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function FeedbackForm() {
  let navigate = useNavigate();
  const [madatoryError, setMadatoryError] = React.useState(false);
  const [inputField, setInputField] = React.useState({
    name: "",
    email: "",
    comment: "",
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: isMobile() ? "70%" : "50%",
    },
  };

  const inputsHandler = (e: any) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event: any) => {
    setMadatoryError(false);
    event.preventDefault();
    console.log(inputField);
    const { name, email, comment } = inputField;
    if (name === "" || email === "" || comment === "") {
      setMadatoryError(true);
    } else {
      const emailString = ` 
      Name: ${name},
      Email: ${email}, 
      Comment: ${comment}
      
      -- FROM IGNITED MINDS FEEDBACK FORM --`;

      var data = JSON.stringify({
        subject: `Feedback from ${name}`,
        message: emailString,
        to: true,
      });

      var config: any = {
        method: "post",
        url: "https://gko3icoky7.execute-api.us-east-1.amazonaws.com/default/emailFunction",
        headers: {
          "x-api-key": "MRQRpNPSMr3U5s9xOagiz7UUxHaQCQBf5OY0ESOt",
          "Content-Type": "application/json",
        },
        data: data,
      };
      console.log(config);

      axios(config).then(function (response: any) {
        console.log(JSON.stringify(response.data));
        setIsOpen(true);
      });
    }
  };

  function closeModal() {
    setIsOpen(false);
    navigate("/home", { replace: true });
  }

  return (
    <React.Fragment>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit}>
          <div className={styles.formTitle}>Feedback Form</div>
          <div className={styles.fieldContainer}>
            <label className={styles.label}>Name * </label>
            <input
              className={styles.input}
              name="name"
              type="text"
              value={inputField.name}
              autoComplete="off"
              onChange={inputsHandler}
            />
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label}>Email * </label>
            <input
              className={styles.input}
              name="email"
              type="email"
              autoComplete="off"
              value={inputField.email}
              onChange={inputsHandler}
            />
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label}>Comment * </label>
            <textarea
              className={styles.input}
              name="comment"
              autoComplete="off"
              value={inputField.comment}
              onChange={inputsHandler}
            />
          </div>
          {madatoryError && (
            <div className={styles.errorMsg}>
              Please fill all the required* fields to continue
            </div>
          )}
          <button className={styles.submitButton} type="submit">
            Submit
          </button>
        </form>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <PopupModal
          closePopup={closeModal}
          msg="Thank you!"
          subMsg="By making your voice heard, you help us improve. We’re looking forward to making your experience even better in the future!"
        ></PopupModal>
      </Modal>
    </React.Fragment>
  );
}
