import styles from "./styles.module.css";
import car from "../../assets/group_4.png";
import OPHTHALMOLOGY from "../../assets/ic_ophthalmology_3.png"; //
import SURGERY from "../../assets/group_320.png";
import OBG from "../../assets/group_321.png";
import GENERAL_MEDICINE from "../../assets/group_322.png";
import MICROBIOLOGY from "../../assets/group_357.png";
import PHYSIOLOGY from "../../assets/group_410.png";
import PHARMACOLOGY from "../../assets/group_411.png";
import PEDIATRICS from "../../assets/group_414.png";
import ENT from "../../assets/group_415.png";
import PATHOLOGY from "../../assets/ic_pathology_2.png";
import COMMUNITY_MEDICINE from "../../assets/group_6.png";
import BIOCHEMISTRY from "../../assets/download (7).jpg";
import USA from "../../assets/download (8).jpg";

import React from "react";
import isMobile from "is-mobile";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Swiper from "swiper/bundle";

export default function ChoiceOfLearing() {
  const list = [
    [
      {
        name: "USA",
        icon: "https://www.worldometers.info//img/flags/small/tn_us-flag.gif",
      },
      {
        name: "UK",
        icon: "https://www.worldometers.info//img/flags/small/tn_uk-flag.gif",
      },
      {
        name: "Ireland",
        icon: "https://www.worldometers.info//img/flags/small/tn_ei-flag.gif",
      },
      {
        name: "Australia",
        icon: "https://www.worldometers.info//img/flags/small/tn_as-flag.gif",
      },
      {
        name: "New Zealand",
        icon: "https://www.worldometers.info//img/flags/small/tn_nz-flag.gif",
      },
    ],
    [
      {
        name: "Canada",
        icon: "https://www.worldometers.info//img/flags/small/tn_ca-flag.gif",
      },
      {
        name: "Italy",
        icon: "https://www.worldometers.info//img/flags/small/tn_it-flag.gif",
      },
      {
        name: "Netherlands",
        icon: "https://www.worldometers.info//img/flags/small/tn_nl-flag.gif",
      },
      {
        name: "Sweden",
        icon: "https://www.worldometers.info//img/flags/small/tn_sw-flag.gif",
      },
      {
        name: "Germany",
        icon: "https://www.worldometers.info//img/flags/small/tn_gm-flag.gif",
      },
    ],
    [
      {
        name: "France",
        icon: "https://www.worldometers.info//img/flags/small/tn_fr-flag.gif",
      },
      {
        name: "Singapore",
        icon: "https://www.worldometers.info//img/flags/small/tn_sn-flag.gif",
      },
      {
        name: "Malaysia",
        icon: "https://www.worldometers.info//img/flags/small/tn_my-flag.gif",
      },
      {
        name: "",
        // icon: "https://www.worldometers.info/img/flags/ch-flag.gif",
      },
      {
        name: "",
        // icon: "https://www.worldometers.info/img/flags/ch-flag.gif",
      },
    ],
  ];
  // const list = [
  //   [
  //     {
  //       name: "USA",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_us-flag.gif",
  //     },
  //     {
  //       name: "UK",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_uk-flag.gif",
  //     },
  //     {
  //       name: "Canada",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_ca-flag.gif",
  //     },
  //     {
  //       name: "Australia",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_as-flag.gif",
  //     },
  //   ],
  //   [
  //     {
  //       name: "Germany",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_gm-flag.gif",
  //     },
  //     {
  //       name: "New Zealand",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_nz-flag.gif",
  //     },
  //     {
  //       name: "Ireland",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_ei-flag.gif",
  //     },
  //     {
  //       name: "Italy",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_it-flag.gif",
  //     },
  //   ],
  //   [
  //     {
  //       name: "Malaysia",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_my-flag.gif",
  //     },
  //     {
  //       name: "Dubai", //FORENSIC MEDICINE
  //       icon: "https://www.worldometers.info/img/flags/ae-flag.gif",
  //     },
  //     {
  //       name: "Vietnam",
  //       icon: "https://www.worldometers.info/img/flags/vm-flag.gif",
  //     },
  //     {
  //       name: "France",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_fr-flag.gif",
  //     },
  //   ],
  //   [
  //     {
  //       name: "Netherlands",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_nl-flag.gif",
  //     },
  //     {
  //       name: "Sweden",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_sw-flag.gif",
  //     },
  //     {
  //       name: "Japan", //ORTHOPEDICS
  //       icon: "https://www.worldometers.info//img/flags/small/tn_ja-flag.gif",
  //     },
  //     {
  //       name: "Singapore",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_sn-flag.gif",
  //     },
  //   ],

  //   [
  //     {
  //       name: "Switzerland",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_sz-flag.gif",
  //     },
  //     {
  //       name: "Finland",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_fi-flag.gif",
  //     },
  //     {
  //       name: "Austria", //ORTHOPEDICS
  //       icon: "https://www.worldometers.info//img/flags/small/tn_au-flag.gif",
  //     },
  //     {
  //       name: "Hungary",
  //       icon: "https://www.worldometers.info//img/flags/small/tn_hu-flag.gif",
  //     },
  //   ],
  //   [
  //     {
  //       name: "Spain",
  //       icon: "https://www.worldometers.info/img/flags/sp-flag.gif",
  //     },
  //     {
  //       name: "Poland",
  //       icon: "https://www.worldometers.info/img/flags/pl-flag.gif",
  //     },
  //     {
  //       name: "Denmark",
  //       icon: "https://www.worldometers.info/img/flags/da-flag.gif",
  //     },
  //     {
  //       name: "Lithuania",
  //       icon: "https://www.worldometers.info/img/flags/lh-flag.gif",
  //     },
  //   ],
  //   [
  //     {
  //       name: "Cyprus",
  //       icon: "https://www.worldometers.info/img/flags/cy-flag.gif",
  //     },
  //     {
  //       name: "Czech Republic",
  //       icon: "https://www.worldometers.info/img/flags/ez-flag.gif",
  //     },
  //     {
  //       name: "Mauritius",
  //       icon: "https://www.worldometers.info/img/flags/mp-flag.gif",
  //     },
  //     {
  //       name: "Malta",
  //       icon: "https://www.worldometers.info/img/flags/mt-flag.gif",
  //     },
  //   ],
  //   [
  //     {
  //       name: "China",
  //       icon: "https://www.worldometers.info/img/flags/ch-flag.gif",
  //     },
  //     {
  //       name: "",
  //       // icon: "https://www.worldometers.info/img/flags/ch-flag.gif",
  //     },
  //   ],
  // ];
  const list2 = list.flatMap((videoLink) => videoLink);

  const swiper = new Swiper(".swiper_yt", {
    // If we need pagination
    pagination: {
      el: ".swiper_yt-pagination",
      clickable: true,
    },
  });
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.main_heading}>Countries To Explore</div>
        {/* {!isMobile() && (
          <div className={styles.sub_heading}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </div>
        )} */}
      </div>
      {!isMobile() && (
        <div className={styles.learning_list_container}>
          {list2.map(({ name, icon }, index) => (
            <div
              style={name === "" ? { border: "1px", opacity: "0" } : {}}
              className={styles.learning_item}
              key={index}
            >
              <img
                style={name === "" ? { display: "none" } : {}}
                className={styles.learning_item_icon}
                src={icon}
              />
              <div className={styles.learning_item_name}>{name}</div>
            </div>
          ))}
        </div>
      )}

      {isMobile() && (
        <div className="swiper_yt">
          <div className="swiper-wrapper">
            {list.map((array1, index) => {
              return (
                <div className="swiper-slide" key={index}>
                  <div className="col_cont" key={index}>
                    {array1.map(({ name, icon }, index) => {
                      return (
                        <div
                          style={
                            name === "" ? { border: "1px", opacity: "0" } : {}
                          }
                          className={styles.learning_item}
                          key={index}
                        >
                          <img
                            style={name === "" ? { display: "none" } : {}}
                            className={styles.learning_item_icon}
                            src={icon}
                          />
                          <div className={styles.learning_item_name}>
                            {name}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="pagination_container">
            <div className="swiper_yt-pagination">...</div>
          </div>
        </div>
      )}
    </div>
  );
}
