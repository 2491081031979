import styles from "./styles.module.css";
import frame from "../../assets/rectangle_186.png";

import frame_two from "../../assets/rectangle_186_4.png";
import hotelbooking from "../../assets/Hotel Booking-bro.svg";
import line from "../../assets/kids wearing masks at school-pana.svg";
import savings from "../../assets/Savings-rafiki.svg";
import airport from "../../assets/Airport-rafiki.svg";
import homee from "../../assets/homeee.svg";

import mbbs_gif from "../../assets/download (6).jpg";
import lessons from "../../assets/Lesson-pana.svg";
import counsling from "../../assets/counsling.svg";
import takingnotes from "../../assets/Taking notes-amico.svg";
import blob from "../../assets/blob.svg";

import isMobile from "is-mobile";
import React from "react";
import UniqueFeaturesDev from "../uniqueFeatureDev";

export default function UniqueFeatures() {
  const dummy_description =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis no consequat.";
  const featureList = [
    {
      img: counsling,
      title: "Personalized overseas education Counselling",
      description:
        "Choosing and applying to any international university is a complex and time-consuming process. Our admission services offer a personal and discrete advisory in securing a place at the best university of your choice. We work with you to advice on every step of the application process.",
      description2: "Attend our free counselling session.",
    },
    {
      img: lessons,
      title: "Test Preparation: IELTS, GRE, GMAT, TOEFL, SAT",
      description:
        "Many universities abroad may require you to meet SAT, ACT, GRE or GMAT and English language standards. For some, this can mean preparing for weeks before moving onto your University course. But it’s important to note that these requirements vary between study programs and levels. So we’ll help you find out what the requirements are for your course and advice on how to achieve this.",
      description2: "Speak to us for more details.",
    },
    {
      img: takingnotes,
      title: "Profile Evaluation with country, course and university selection",
      description:
        "We will assist you in shortlisting the options relevant to your profile and preferences by taking into consideration your educational background and career objectives. We then suggest programs in the countries and universities suiting your preferences, highlighting the areas essential for a well-presented, error-free application. If you’re worried about entry requirements, we’ll work with you to look for alternative pathways. There is no problem we won’t happily take on.",
    },
    {
      img: homee,
      title: "Assistance in document preparation",
      description:
        "We will provide you with the right guidelines for drafting an effective Statement of Purpose (SOP) and help with letter of recommendation samples. We will guide and assist you with interview preparation and mock interviews to ensure that you are prepared.",
    },
    {
      img: line,
      title: "University Application and admission support",
      description:
        "After course & university selection to study abroad, the need for Application & Admission Assistance arises. Small oversights on an application can cause major delays for your offer, which is why we are here to support you. Your application to the university is a deciding factor in getting the offer. It is your first introduction to the admissions committee. We will assist you in filling the applications with precision by helping our students at every stage of the admission process to actively track the same for university decision on the application",
    },

    {
      img: savings,
      title: "Get Application Fee Waivers",
      description:
        "You can count on us to ensure that our students avail all the benefits of promotional offers and schemes of universities for any application fee waivers when applicable.",
    },

    {
      img: airport,
      title: "Visa Counselling and Processing",
      description:
        "The most important step in studying abroad is visa processing. Our visa service assists in securing student visas. We will guide you step by step with the visa-processing right from filling in the visa application forms to getting your documents ready and submitting them on time. We also conduct mock visa interviews to give you a feel of the actual visa interview. (Wherever required)",
    },

    {
      img: hotelbooking,
      title:
        "Value added services: Pre-departure briefing, Forex and Accommodation assistance",
      description:
        "We certainly understand the excitement of studying overseas, but we also know it can be a nerve-wracking journey. We provide pre-departure briefings which helps you to have an idea of the essentials to be kept in mind before you fly to your dream destination. Our job is to set you and your family’s mind at ease by providing you with accurate detailed information about what you can expect upon arrival. Any questions you have about life and study in your preferred country can be answered before you depart. To make sure that you feel at ease once you land into your dream destination, we also have accommodation assistance in place. Whether you’re looking for university accommodation, your own studio or even a flat you can share with friends, we will offer you the best choice of location and room type. All this is to ensure that you feel at home even in a foreign nation.",
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.title}>Explore our Services</div>
      {/* <div className={styles.mobile_view_top_img}>
        <img src={rectangle_11}></img>
      </div> */}

      <div className={styles.intro_service}>
        Do you wish for an offer from the University of your Dreams? Or just
        need advice on course requirements? At <b>Ignited Minds</b> we offer
        best-in-class services to our students and can help you kick-start your
        journey to study abroad. We will be right there with you fulfilling your
        dreams of studying abroad, right from the process of application to
        enrollment, making sure you get to where you need to go. We are proud to
        offer expert advice that puts you at the center of the process.
      </div>
      {featureList.map((feature, index) => {
        return (
          <React.Fragment>
            <div className={styles.feature} key={index}>
              {isMobile() && (
                <div>
                  {(index % 2 === 0 && (
                    <div className={styles.dividerBar}></div>
                  )) || <div className={styles.dividerBar2}></div>}

                  <div className={styles.feature_flex1}>
                    <img className={styles.feature_img} src={feature.img} />
                  </div>
                </div>
              )}
              {!isMobile() && index % 2 === 0 && (
                <div>
                  {" "}
                  <div className={styles.dividerBar2}></div>
                  <div className={styles.feature_flex1}>
                    <img className={styles.feature_img} src={feature.img} />
                  </div>
                </div>
              )}

              <div className={styles.feature_flex2}>
                <div className={styles.feature_flex2_data_cont}>
                  <div className={styles.feature_title}> {feature.title}</div>
                  <div className={styles.feature_description}>
                    {feature.description}
                  </div>
                  {feature.description2 && (
                    <div className={styles.feature_description}>
                      {feature.description2}
                    </div>
                  )}
                </div>
              </div>
              {!isMobile() && index % 2 != 0 && (
                <div className={styles.right_cont}>
                  <div className={styles.dividerBar}></div>
                  <div className={styles.feature_flex1}>
                    <img className={styles.feature_img2} src={feature.img} />
                  </div>
                </div>
              )}
            </div>
            {/* {index != 4 && (
              <UniqueFeaturesDev index={index}></UniqueFeaturesDev>
            )} */}
          </React.Fragment>
        );
      })}
      <div className={styles.spclMsg}>Your success is our success</div>
    </div>
  );
}
