import React from "react";
import NavBar from "../../components/navBar";
import styles from "./styles.module.css";
import FeedbackForm from "../../components/feedbackForm";
import google from "../../assets/google.png";
import instagram from "../../assets/instagram.png";
import facebook from "../../assets/facebook.png";
import linkedin from "../../assets/linkedin.png";
import email from "../../assets/envelope.png";
import feedbackkk from "../../assets/Feedback.gif";
import phone from "../../assets/phone-call.png";
import clock from "../../assets/Feedback-rafiki.svg";
import Footer from "../../components/footer";
import isMobile from "is-mobile";
export default class Feedback extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <NavBar></NavBar>
        <div className={styles.continer}>
          {isMobile() && (
            <div className={styles.flex2}>
              <FeedbackForm />
            </div>
          )}
          <div className={styles.flex1}>
            <div className={styles.contact_container}>
              <div className={styles.fb_title}>Feel Free to drop us a line and give us your feedbacks</div>
              <div className={styles.fb_sub_title}>We are looking forward to hearing back from you</div>
            </div>

            <img className={styles.feedbbb} src={feedbackkk} />
          </div>
          {!isMobile() && (
            <div className={styles.flex2}>
              <FeedbackForm />
            </div>
          )}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}
