import styles from "./styles.module.css";
import Iframe from "react-iframe";

export default function MapComponent() {
  return (
    <div className={styles.container}>
      <div className={styles.map_frame}>
        <Iframe
          url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.6099768176045!2d74.87010941461077!3d12.86844899092369!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba35be56f3a45cf%3A0x710074407974c76!2sIgnited%20Minds!5e0!3m2!1sen!2sin!4v1642877385006!5m2!1sen!2sin"
          width="100%"
          height="100%"
          id="myId"
          loading="lazy"
          className={styles.map_frame}
          position="relative"
        />

        {/* 
        <div className={styles.map_info}>
          <div className={`${styles.main_adress}`}>
            <button className={styles.adress_ui}>
              <img className={styles.adress_icon} src={email_icon} />
            </button>
            Address
          </div>
          <div className={`${styles.address_name}`}>
            DRPOLARIS Pvt Ltd 3rd Floor-Empire Mall, MG Rd, Lalbagh, Mangaluru,
            Karnataka 575003
          </div>
        </div> */}
      </div>
    </div>
  );
}
