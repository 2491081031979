import styles from "./styles.module.css";

import React from "react";
import Select from "react-select";
import mailSent from "../../assets/Mail sent-rafiki (3).svg";
import { useNavigate } from "react-router-dom";

export default function FeedbackReq() {
  let navigate = useNavigate();

  const handleEnquireClick = () => {
    navigate("../feedback", { replace: true });
  };

  return (
    <div className={styles.reqBocContainer}>
      <div>
        <span>
          <span className={styles.reqMsg}>Send us your feedback!</span>
          <button onClick={handleEnquireClick} className={styles.submitButton} type="submit">
            Feedback
          </button>
        </span>
      </div>
      <div>
        <img className={styles.semtimg} src={mailSent}></img>
      </div>
    </div>
  );
}
