import styles from "./styles.module.css";
import feedbackkk from "../../assets/Mail sent.gif";
import close from "../../assets/close (1).png";

export default function PopupModal(props: any) {
  const { msg, subMsg } = props;
  return (
    <div>
      <img
        className={styles.close}
        src={close}
        onClick={props.closePopup}
      ></img>
      <div className={styles.container}>
        <div className={styles.msgBlock}>
          <div className={styles.mainMsg}>{msg}</div>
          <div className={styles.subMsg}>{subMsg}</div>
        </div>

        <div>
          <img className={styles.gif} src={feedbackkk} />
        </div>
      </div>
    </div>
  );
}
