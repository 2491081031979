import styles from "./styles.module.css";

import React, { useEffect } from "react";
import Select from "react-select";
import Modal from "react-modal";
import PopupModal from "../mailSentModal";
import isMobile from "is-mobile";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function FeaturedIn() {
  let navigate = useNavigate();
  const [inputField, setInputField] = React.useState({
    fName: "",
    lName: "",
    email: "",
    phone: "91",
    cource: [],
    country: [],
    fundEducation: "",
    studyLevel: "",
    comment: "",
  });

  const [madatoryError, setMadatoryError] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: isMobile() ? "70%" : "50%",
    },
  };

  const handleSubmit = (event: any) => {
    setMadatoryError(false);
    event.preventDefault();
    const {
      fName,
      lName,
      email,
      phone,
      comment,
      country,
      cource,
      fundEducation,
      studyLevel,
    } = inputField;
    if (
      fName === "" ||
      lName === "" ||
      email === "" ||
      fundEducation === "" ||
      studyLevel === "" ||
      phone === "" ||
      [...cource, ...country].length === 0
    ) {
      setMadatoryError(true);
    } else {
      const data1: any = fundEducation;
      const data2: any = studyLevel;
      const contriesList = country.map((data: any) => data.label);
      const courceList = cource.map((data: any) => data.label);
      const emailString = ` 
      Name: ${fName} ${lName},
      Email: ${email}, 
      Phone: ${phone}, 
      Preffered area of study: ${courceList.join(", ")},
      Preffered destination: ${contriesList.join(", ")}, 
      Planned Education Fund: ${data1.label},
      Preferred study level: ${data2.label}
      Comment: ${comment}
      
      -- FROM IGNITED MINDS ENQUIRY FORM --`;

      var data = JSON.stringify({
        subject: `Enquiry By ${fName} ${lName}`,
        message: emailString,
        to: true,
      });

      var config: any = {
        method: "post",
        url: "https://gko3icoky7.execute-api.us-east-1.amazonaws.com/default/emailFunction",
        headers: {
          "x-api-key": "MRQRpNPSMr3U5s9xOagiz7UUxHaQCQBf5OY0ESOt",
          "Content-Type": "application/json",
        },
        data: data,
      };
      console.log(config);

      axios(config).then(function (response: any) {
        console.log(JSON.stringify(response.data));
        setIsOpen(true);
      });
    }
  };

  function closeModal() {
    setIsOpen(false);
    navigate("/home", { replace: true });
  }

  const inputsHandler = (e: any) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };

  const updateSelectedCource = (e: any) => {
    setInputField({ ...inputField, cource: e });
  };

  const updateSelectedCountry = (e: any) => {
    setInputField({ ...inputField, country: e });
  };

  const updateSelectedFundtype = (e: any) => {
    setInputField({ ...inputField, fundEducation: e });
  };

  const updateSelectedStudyLevel = (e: any) => {
    setInputField({ ...inputField, studyLevel: e });
  };

  const data = [
    "Accounting",
    " Agriculture Sciences",
    " Art and Design",
    " Biological and Life Sciences",
    " Building and Architecture",
    " Business",
    " Chemistry",
    " Computing and IT",
    " Economics",
    " Engineering",
    " Environmental Science",
    " Finance",
    " Geography",
    " Health and Medicine",
    " History",
    " Hospitality and Tourism",
    " Human Welfare Studies and Services",
    " Information Science",
    " Language and Culture",
    " Law and Legal Studies",
    " Marketing, Media and Communication",
    " Performing Arts and Music",
    " Political and Social Sciences",
    " Teaching and Education",
    " Pharmacy",
    " Physics",
    "Other"
  ];

  const courceOptions = data.map((courceName, index) => {
    return { value: index + 1, label: courceName };
  });

  let fff = [
    "USA",
    "UK",
    "Canada",
    "Australia",
    "Germany",
    "New Zealand",
    "Ireland",
    "Italy",
    "Malaysia",
    "Dubai",
    "Vietnam",
    "France",
    "Netherlands",
    "Sweden",
    "Japan",
    "Singapore",
    "Switzerland",
    "Finland",
    "Austria",
    "Hungary",
    "Spain",
    "Poland",
    "Denmark",
    "Lithuania",
    "Cyprus",
    "Czech Republic",
    "Mauritius",
    "Malta",
    "China",
    "Other"
  ];

  const countryList = fff.map((courceName, index) => {
    return { value: index + 1, label: courceName };
  });

  const educationFund = [
    { value: 1, label: "Self-Funded" },
    { value: 2, label: "Seeking Scholarship" },
    { value: 3, label: "Parents" },
    { value: 4, label: "Seeking Government Scholarship" },
    { value: 5, label: "Have Government Scholarship" },
    { value: 6, label: "Bank Loan" },
    { value: 7, label: "Employer Scholarship" },
    { value: 7, label: "Other" },
  ];

  const studyLevel = [
    { value: 1, label: "Doctorate" },
    { value: 2, label: "Postgraduate" },
    { value: 3, label: "Undergraduate" },
    { value: 4, label: "University Preparation" },
    { value: 5, label: "Vocational" },
    { value: 6, label: "English Language" },
    { value: 7, label: "School" },
    { value: 7, label: "Other" },
  ];

  useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div className={styles.formContainer}>
        <form onSubmit={handleSubmit}>
          <div className={styles.formTitle}>Enquiry form</div>
          <div className={styles.fieldContainer}>
            <label className={styles.label}>First Name * </label>
            <input
              autoComplete="off"
              className={styles.input}
              name="fName"
              type="text"
              value={inputField.fName}
              onChange={inputsHandler}
            />
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label}>Last Name * </label>
            <input
              autoComplete="off"
              className={styles.input}
              name="lName"
              type="text"
              value={inputField.lName}
              onChange={inputsHandler}
            />
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label}>Email Address * </label>
            <input
              autoComplete="off"
              className={styles.input}
              name="email"
              type="email"
              value={inputField.email}
              onChange={inputsHandler}
            />
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label}>Phone Number * </label>
            <input
              autoComplete="off"
              className={styles.input}
              name="phone"
              type="number"
              value={inputField.phone}
              onChange={inputsHandler}
            />
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label}>Preferred area of Study * </label>
            <Select
              options={courceOptions}
              onChange={updateSelectedCource}
              isMulti
            />
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label}>
              Preferred study destination *
            </label>
            <Select
              options={countryList}
              onChange={updateSelectedCountry}
              isMulti
            />
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label}>
              How would you fund your education *
            </label>
            <Select options={educationFund} onChange={updateSelectedFundtype} />
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label}>Preferred study level *</label>
            <Select options={studyLevel} onChange={updateSelectedStudyLevel} />
          </div>
          <div className={styles.fieldContainer}>
            <label className={styles.label}>Comment </label>
            <textarea
              className={styles.input}
              name="comment"
              value={inputField.comment}
              onChange={inputsHandler}
            />
          </div>
          {madatoryError && (
            <div className={styles.errorMsg}>
              Please fill all the required* fields to continue
            </div>
          )}

          <div className={styles.fieldContainer}>
            <input
              type="checkbox"
              id="confirm"
              name="access"
              value="yes"
              required
            />
            <label className={styles.checkboxLabel}>
              Please contact me by phone, email or SMS to assist with my enquiry
            </label>
          </div>

          <button className={styles.submitButton} type="submit">
            Submit
          </button>
        </form>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <PopupModal
          closePopup={closeModal}
          msg="Sit back and Relax!"
          subMsg="We have heard you. Our team would evaluate your requirement and connect back with you soon"
        ></PopupModal>
      </Modal>
    </React.Fragment>
  );
}
