import { useEffect, useState } from "react";
import "./App.css";
import Home from "./pages/home";
import Enquire from "./pages/enquire";
import upArrow from "./assets/whatsapp.png";
import { Navigate, Route } from "react-router-dom";
import { BrowserRouter as Router, Routes } from "react-router-dom";
import Feedback from "./pages/feedback";

function App() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  // This function will scroll the window to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // for smoothly scrolling
    });
  };

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<Home />} />
          <Route path="/enquire" element={<Enquire />} />
          <Route path="/feedback" element={<Feedback />} />
        </Routes>
      </Router>

      <a
        href="https://wa.me/917204094026"
        target="_blank"
        rel="noopener noreferrer"
      >
        {/* <button  className="back-to-top"> */}
        <img className="back-to-top" src={upArrow} />

        {/* </button>{" "} */}
      </a>
    </div>
  );
}

export default App;
