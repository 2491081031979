// import logo from "../../assets/Logo-removebg-preview.svg";
import logo from "../../assets/dd.png";
import styles from "./styles.module.css";
// import searchIcon from "../../assets/bytesize_search.png";
import { useEffect, useState } from "react";
// import footerLogo from "../../assets/logo3.png";
// import isMobile from "is-mobile";
import { Link, NavLink } from "react-router-dom";
import isMobile from "is-mobile";

export default function NavBar() {
  const [isHam, setisHam] = useState(false);

  const navbarbasicPages = [
    {
      page: "Home",
      path: "/home",
    },
    // {
    //   page: "About Us",
    // },
    // {
    //   page: "Tutors",
    // },
    // {
    //   page: "Careers",
    // },
    {
      page: "Contact Us",
      path: "/enquire",
    },
  ];

  const openSideNav = () => {
    if (isMobile()) {
      if (isHam) document.body.style.overflow = "unset";
      else document.body.style.overflow = "hidden";
      setisHam(!isHam);
    }
  };

  return (
    <header className={styles.header}>
      <nav className={styles.navbar}>
 
          <img className={styles.nav_logo} src={logo} />
   

        <ul className={`${styles.nav_menu} ${isHam ? styles.active : styles.innActive}`}>
          {navbarbasicPages.map((pageDetail: any, index) => {
            return (
          <li className={styles.nav_item} key={index} onClick={() => openSideNav()}>
                 <NavLink to={pageDetail.path}  className={({ isActive }) => (isActive ? styles.nav_link_active : styles.nav_link)} >
                  {pageDetail.page}
                </NavLink>
              </li>
            );
          })}
          {/* <div className={styles.search_container}>
            <img src={searchIcon} />
          </div> */}
          {/* <button
            className={isHam ? styles.login_button_side : styles.login_button}
          >
            Login/Sign Up
          </button> */}
          {/* {isHam && <img className={styles.footerLogo} src={logo} />} */}
        </ul>
        {/* {isMobile() && (
          <div className={styles.search_container_mobile}>
            <img src={searchIcon} />
          </div>
        )} */}
        <div
          className={`${styles.hamburger} ${isHam ? styles.active : ""}`}
          onClick={openSideNav}
        >
          <span className={`${styles.bar} ${isHam ? styles.white : ""}`}></span>
          <span className={`${styles.bar} ${isHam ? styles.white : ""}`}></span>
          <span className={`${styles.bar} ${isHam ? styles.white : ""}`}></span>
        </div>
      </nav>
    </header>
  );
}
